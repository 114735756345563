import artistImage from "./artists/kidmizu/kid-mizu.png";
// @ts-ignore
import Song from "./artists/kidmizu/chill-in-akihabara.wav";
import { TuneBox } from "./components/TuneBox";
import { Container } from "./styles";
// import Rolla from "./assets/rolla.png";
// import Bad from "./assets/bad.png";
import Arcade from "./assets/arcade.webp";
import Website from "./assets/website.png";
// import PokerImage from './assets/poker.png';
import Nifty from "./assets/nifty.png";
import Vineclimb from "./assets/vine-climb.jpeg";
import RigImage from "./assets/rig.png";
// import SeshRace from "./assets/seshrace.png";
import RoadmapTile, { RoadmapBox } from "./components/Roadmap";
// import TeamTile, { TeamWrapper } from "./components/Team";
import Scroll from "./components/Scroll";
import Intro from "./components/Intro";
import Rig from "./components/Rig";
import BubbleGumCards from "./components/BubbleGumCards";
import SecretMint from "./components/SecretMint";

export type PlayIconToggleProps = {
  variant?: "PLAY" | "PAUSE";
  on?: boolean;
};

const Tracks = [
  {
    title: "Chill in Akihabara",
    artist: "Kid Mizu",
    time: "02:47",
    image: artistImage,
    audioSrc: Song,
    artistLink: "https://www.youtube.com/@KidMizu",
  },
];

function App() {
  return (
    <Container>
      <TuneBox tracks={Tracks} />
      <Scroll />
      <Intro />
      <BubbleGumCards />
      <Rig />
      {/* <TeamWrapper>
        <TeamTile
          img={Rolla}
          name="Rollablazer"
          title="Chief Executive Officer"
          description="A seasoned Digital Product Designer with expertise in designing, coding, & curating digital experiences."
          link="https://twitter.com/rollablazer"
          linkTitle="@rollablazer"
        />
        <TeamTile
          img={Bad}
          name="Baddownload"
          title="Director of Marketing"
          description="A Multidisciplinary Designer who supports the project via valuable Art Direction and Marketing advisory."
          link="https://twitter.com/baddownloadxyz"
          linkTitle="@baddownloadxyz"
        />
      </TeamWrapper> */}

      <RoadmapBox>
        <RoadmapTile
          title="Game Rig"
          img={RigImage}
          type="Completed"
          complete={true}
        />
        <RoadmapTile
          title="NiftyIsland"
          img={Nifty}
          type="Soon"
          complete={true}
        />
        <RoadmapTile
          title="VineClimb"
          img={Vineclimb}
          type="Playable"
          complete={true}
        />
        <RoadmapTile
          title="Arcade.inc"
          img={Arcade}
          type="Purchased"
          complete={true}
        />
        <RoadmapTile
          title="Website"
          img={Website}
          type="Published"
          complete={true}
        />
        {/* <RoadmapTile
            title="Sesh Race 64"
            img={SeshRace}
            type="Idea"
            complete={false}
          /> */}
        {/* <RoadmapTile
            title="Nifty Island" 
            img={Otherside} 
            type="Idea"
            complete={false}
          /> */}
      </RoadmapBox>
      <SecretMint />

      {/* <Poker /> */}
    </Container>
  );
}

export default App;
