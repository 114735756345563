import { FC } from "react";
import styled from "@emotion/styled";
// import Rolla from '../assets/sesh-in-game.gif';
import Rolla from "../assets/sesh-rig.webm";

const Title = styled.h1`
  margin: 0;
  line-height: 1;
  font-size: 28px;
  font-family: bungee, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ed7029;
  @media (min-width: 1024px) {
    font-size: 54px;
  }
`;

const SubHeading = styled.p`
  margin: 0;
  font-weight: 400;
  font-style: normal;
  max-width: 52ch;
  font-size: 16px;
  text-align: center;
  margin: 0px 0px 12px;
  max-width: 68ch;
  font-weight: 800;
  color: white;
  text-shadow: 0 4px 8px rgba(0 0 0 / 0.2);
  @media (min-width: 1024px) {
    font-size: 21px;
  }
`;

const Section = styled.section`
  z-index: 0;
  background: rgb(59, 38, 6);
  background: linear-gradient(
    180deg,
    rgba(59, 38, 6, 1) 0%,
    rgba(19, 13, 4, 1) 100%
  );
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  alignitems: center;
  padding: 90px 48px 0;
  @media (min-width: 1024px) {
    padding: 90px 90px 0px 90px;
    background-attachment: fixed;
  }
`;

const Link = styled.a`
  color: #ff8a47;
  &:hover {
    color: #ff8a47;
  }
  &:visited {
    color: #ff8a47;
  }
  &:active {
    color: #ff8a47;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-content: center;
  max-width: 600px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 24px 0 0;
`;

const Tag = styled.span`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  margin: 8px;
  text-transform: uppercase;
  background: #ffffff75;
  font-weight: 900;
  padding: 4px 8px;
  border-radius: 4px;
  color: var(--primary);
  font-family: helvetica;
}
`;

// export const Profile = styled.div`
//   width: 200px;
//   height: 200px;
//   border-radius: 24px;
//   overflow: hidden;
//   box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.35);
//   margin-bottom: 24px;
//   border: 4px solid rgb(221 80 249 / 62%);
//   @media (min-width: 1024px) {
//     width: 250px;
//     height: 250px;
//   }
// `;

export const Characters = styled.div`
  height: auto;
  display: flex;
  border-radius: 24px;
  overflow: hidden;
  @media (min-width: 1024px) {
    width: 700px;
  }
`;

export const Rig: FC = () => {
  return (
    <Section>
      <Wrap>
        <Tag>Completed</Tag>
        <Title>3D Rigged Character</Title>
        <SubHeading>
          Try{" "}
          <Link
            href="https://twitter.com/VineClimbGame"
            target="_blank"
            rel="noreferrer"
          >
            Vine Climb
          </Link>{" "}
          today! Created by{" "}
          <Link
            href="https://twitter.com/NexusVReality"
            target="_blank"
            rel="noreferrer"
          >
            Nexus Corp
          </Link>
        </SubHeading>
        <Characters>
          <video
            width="100%"
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "auto" }}
          >
            <source src={Rolla} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Characters>
      </Wrap>
    </Section>
  );
};

export default Rig;
